
.manage-table__input-con {
  position: relative;
}

.manage-table__krw-hangul-con {
  position: absolute;
  top: -8px;
  left: 0;
  font-size: 1.1rem;
  white-space: nowrap;
  line-height: 1;  
}

.manage-table__krw-hangul {
  position:relative;
  z-index: 10;
}

